// ============================================================================
// Page elements
// ============================================================================

html {
  height: 100%;
  min-height: 100%;
  font-family: $font-family-default;
  font-size: rem($font-size-base);
  line-height: $line-height-base;
  background-color: $color-background-page;
  color: $color-font;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font: inherit;
}

body {
  position: relative;
  height: 100%;
  background-color: inherit !important;
  @include font-smoothing;

  #root {
    height: inherit;
    > div {
      height: inherit;
      display: flex;
      flex-direction: column;
    }
  }
}
