// ============================================================================
// Tables
// ============================================================================

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th,
td {
  text-align: right;
  padding: $spacing-xs;

  &:first-child {
    text-align: left;
  }
}

th {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: $font-weight-medium;
}

thead tr {
  border-bottom: 1px solid $color-table-border-primary;
}

tbody tr:not(:last-child) {
  border-bottom: 1px solid $color-table-border-secondary;
}

tfoot tr {
  border-top: 1px solid $color-table-border-primary;
}
