// ============================================================================
// Headings & Typography
// ============================================================================

h1,
h2 {
  line-height: $line-height-headline;
}

h3,
h4,
h5 {
  line-height: $line-height-subheadline;
  margin-bottom: $spacing-xxs;
}

h1 {
  font-weight: $font-weight-light;
  font-size: rem(40);

  @include medium {
    font-size: rem(48);
  }
}

h2 {
  font-weight: $font-weight-normal;
  font-size: rem(30);
  margin-bottom: $spacing-xxs;

  @include medium {
    font-size: rem(32);
  }
}

h3 {
  font-weight: $font-weight-medium;
  font-size: rem(26);

  @include medium {
    font-size: rem(30);
  }
}

h4 {
  font-weight: $font-weight-normal;
  font-size: rem(24);

  @include medium {
    font-size: rem(26);
  }
}

h5 {
  font-weight: $font-weight-medium;
  font-size: rem(20);

  @include medium {
    font-size: rem(22);
  }
}

h6 {
  font-weight: $font-weight-medium;
  font-size: rem(18);
}

p:empty {
  display: none;
}

b,
strong {
  font-weight: $font-weight-medium;
}

abbr[title] {
  text-decoration: none;
}
