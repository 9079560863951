// ============================================================================
// Inputs
// ============================================================================

input {
  font-size: rem(16);
  width: 100%;
  color: $color-field-font;
  border: 1px solid $color-field-border;
  border-radius: 4px;
  padding: rem(8) $spacing-xs rem(6);

  &[disabled] {
    background-color: $color-field-disabled-background;
    color: $color-field-disabled-font;
  }
}
