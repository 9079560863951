.SingleDatePickerInput {
  width: 16.5rem;
  border: none;
}

.DateInput {
  width: 100%;

  .DateInput_input {
    width: 100%;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    color: $color-field-font;
    border: 1px solid $color-date-picker-border;
    border-radius: $field-selection-border-radius 0 0 $field-selection-border-radius;
    padding: $spacing-xxs $spacing-xs;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .DateInput_fang {
    display: none;
  }
}

.SingleDatePicker_picker {
  position: relative;
  top: $spacing-xs !important;

  .DayPicker {
    border: 1px solid $color-date-picker-border;
    border-radius: $field-selection-border-radius;
    box-shadow: none;

    .DayPicker_weekHeader {
      color: $color-date-picker-font;
    }

    .DayPickerNavigation {
      .DayPickerNavigation_button__default {
        top: $spacing;
        width: 0.5rem;
        height: 1rem;
        border: none;

        &:hover {
          background: transparent;
        }

        svg {
          display: none;
        }
      }

      .DayPickerNavigation_leftButton__horizontalDefault {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.5rem;
          height: 1rem;
          background: url('/images/icons/datepicker-arrow-left.svg') no-repeat 50% 50%;
        }
      }

      .DayPickerNavigation_rightButton__horizontalDefault {
        right: $spacing-l;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.5rem;
          height: 1rem;
          background: url('/images/icons/datepicker-arrow-right.svg') no-repeat 50% 50%;
        }
      }
    }

    .CalendarMonthGrid,
    .CalendarMonth {
      background-color: transparent;
    }

    .CalendarMonth_caption {
      color: $color-date-picker-font;
    }

    .CalendarMonth_table tr {
      border-bottom: none;
    }

    .CalendarDay__default {
      background-color: transparent;
      color: $color-date-picker-font;
      line-height: $font-size-base;
      border: none;
      border-radius: 50%;

      &:hover {
        background-color: $color-hover-day-background;
      }
    }

    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover,
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      color: $color-disabled-day-font;
      background-color: transparent;
      box-shadow: none;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background-color: $color-selected-day-background;
      color: $color-selected-day-font;
    }
  }
}
