// Accessibility (a11y) helper
// =====================================================
// Visually hides an element, but retains its readability in Screen Readers
@mixin a11y () {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

// Breakpoint helpers
// =====================================================
// Create media queries for each breakpoint

$breakpoint-xsmall: 480px;
$breakpoint-small: 767px;
$breakpoint-medium: $breakpoint-small + 1px;
$breakpoint-large: 1000px;
$breakpoint-xlarge: 1199px;
$breakpoint-xxlarge: 1367px;
$breakpoint-xxxlarge: 1600px;

@mixin xsmall-max {
  @media only screen and (max-width: $breakpoint-xsmall) {
    @content;
  }
}

@mixin xsmall {
  @media only screen and (min-width: $breakpoint-xsmall) {
    @content;
  }
}

@mixin medium-max {
  @media only screen and (max-width: $breakpoint-small) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $breakpoint-large) {
    @content;
  }
}

@mixin xlarge {
  @media only screen and (min-width: $breakpoint-xlarge) {
    @content;
  }
}

@mixin xxlarge {
  @media only screen and (min-width: $breakpoint-xxlarge) {
    @content;
  }
}

@mixin xxxlarge {
  @media only screen and (min-width: $breakpoint-xxxlarge) {
    @content;
  }
}

@mixin clearfix {
  &::after {
    display: table;
    content: '';
    clear: both;
  }
}
@mixin gutters {
  max-width: $max_width;
  margin-right: auto;
  margin-left: auto;

  padding-right: rem(20);
  padding-left: rem(20);
}

@mixin inline-block($align: top) {
  display: inline-block;
  vertical-align: $align;
  *display: inline;
  zoom: 1;
}
// Triangle helper
// ====================================================
// This mixin creates CSS triangles based on the given parameters.
// Especially useful in combination with a `::before` or `::after` pseudo element.

@mixin triangle ($direction: 'down', $colour: inherit, $width: .75rem, $height: .75rem) {
  // Consider adding `display: inline-block;` below
  // display: inline-block;
  height: 0;
  width: 0;
  @if ($colour == inherit) {
    // If the `inherit` keyword is used for the colour of the triangle,
    // first set the `color` on the element to `inherit`
    color: inherit;
    // then reset the `$colour` param to `currentColor`,
    // thereby inheriting a parent element's `color`
    $colour: currentColor;
  }
  @if ($direction == 'up') {
    border-top: none;
    border-right: transparent solid ($width/2);
    border-bottom: $colour solid $height;
    border-left: transparent solid ($width/2);
  }
  @if ($direction == 'down') {
    border-top: $colour solid $height;
    border-right: transparent solid ($width/2);
    border-bottom: none;
    border-left: transparent solid ($width/2);
  }
  @if ($direction == 'left') {
    border-top: transparent solid ($height/2);
    border-right: $colour solid $width;
    border-bottom: transparent solid ($height/2);
    border-left: none;
  }
  @if ($direction == 'right') {
    border-top: transparent solid ($height/2);
    border-right: none;
    border-bottom: transparent solid ($height/2);
    border-left: $colour solid $width;
  }
}

// Hide text helper
// ===============================================
// Hide the text on an element so you can replace
// it with a background image such as an icon.
@mixin hide_text () {
  text-indent: -9999em;
  text-align: left;
}

// Colors
$color_primary: #001e62;
$color_primary_inverse: #fff;

$color_secondary: #007CBE;
$color_secondary_inverse: #fff;
$color_secondary_offwhiter: #EDF3FA;
$color_secondary_offwhite: #d2e2f3;
$color_secondary_light: #449BD8;

$color_special_turquoise: #4BC4D6;
$color_special_aqua: #4EC1B3;

$color_grey_offwhite: #fafafa;
$color_grey_light: #ddd;
$color_grey_med_light: #ccc;
$color_grey: #999;
$color_grey_med_dark: #626262;
$color_grey_dark: #333;

$color_danger: #cb4e0a;
$color_error: #cb0a0a;
$color_success: #29AA81;
$color_caution: #EBB140;
$color_not_applicable: $color_grey;

$color_background: $color_primary_inverse;
$color_border: $color_secondary_offwhite;
$color_accent: $color_secondary_offwhiter;
$color_accent_border: $color_secondary_offwhite;

$color_link: $color_secondary;
$color_link_hover: $color_secondary_light;
$color_link_hover_background: rgba($color_secondary_offwhiter, 0.5);

// Spacing
$sxxs: 0.25rem;
$sxs: 0.5rem;
$ssm: 1rem;
$smd: 2rem;
$slg: 4rem;
$sxl: 6rem;

// REM aid
$rem_calc_base: 16; // this is our 'presumed' default text size in px, which we only use to create a relative point in our rem function (used to convert px into rem)

// Font settings
$font_family: 'BoosterNext', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

$font_weight_light: 100;
$font_weight_normal: 300;
$font_weight_bold: 700;
$font_weight_extrabold: 900;

$font_color: $color_primary;
$font_color_subdued: $color_grey_dark;
$font_color_subdued_more: $color_grey;

  // note: using manual rem-calc calculation here, as our mixin isn't available in variables file
  // converting px sizing to rem equivelent
$font_size_xxs: #{10 / $rem_calc_base}rem;
$font_size_xs: #{12 / $rem_calc_base}rem;
$font_size_sm: #{14 / $rem_calc_base}rem;
$font_size_md: #{16 / $rem_calc_base}rem;
$font_size_lg: #{18 / $rem_calc_base}rem;
$font_size_xl: #{22 / $rem_calc_base}rem;
$font_size_xxl: #{28 / $rem_calc_base}rem;
$font_size_xxxl: #{36 / $rem_calc_base}rem;

$font_line_height_sm: 1.2em;
$font_line_height_md: 1.5em;
$font_line_height_lg: 1.8em;

$letter_spacing_lg: 0.03em;


// These are vars that we had trouble finding a place to live.
//TODO put these vars somewhere that make sense

// Dropdown styles
$dropdown_link_padding: .5rem;
$dropdown_spacer_size: .5rem;
$dropdown_arrow_width: .625rem;
$dropdown_dropdown_arrow_width: .5rem;

// Borders
$border_width: .0625rem;
$border_default: $border_width solid $color_border;
$border_light: $border_width solid $color_secondary_offwhiter;
$border_radius_default: 4px;

//
//  This file maps variables from `react-sass` / `_default.scss` to the website vars used
//  by the `shop-assistant` UI. 
//
//  Thus it has a dependency on `react-sass`'s `variables/_default.scss` which must be processed
//  in your asset pipeline before processing this file.
//
// This file should only scss vars
// there should be NO COMPILED CSS (aka style rules on elements/classes/etc) IN THIS FILE
//

// Theme colours
$colour_primary: $color_primary;
$colour_primary_inverse: $color_primary_inverse;
$colour_secondary: $color_secondary;
$colour_secondary_inverse: $color_secondary_inverse;
$colour_danger: $color_danger;
$colour_error: $color_error;
$colour_success: $color_success;
$colour_caution: $color_caution;
$colour_not_applicable: #7d7d7d;
$colour_accent: #ECF3FB;
$colour_accent_border: #C8DBF1;
$colour_background: $colour_primary_inverse;

// Base colours
$colour_font: $colour_primary;
$colour_link: $colour_primary;
$colour_link_hover: $colour_secondary;
$colour_heading: $colour_secondary;
$colour_focus: $colour_secondary;

// Form-field and button alignment
$field_colour_font: $colour_not_applicable;
$field_colour_font_optional: #bbb;
$field_colour_font_error: $colour_danger;
$field_colour_border_error: $colour_danger;
$field_colour_placeholder: $colour_not_applicable;
$field_colour_background: #F7F9FD;
$field_colour_background_selected: #C8DBF1;
$field_colour_border: #C8DBF1;

$field_border_width: $border_width;
$field_min_height: 2rem;
$field_line_height: 1.25;
$field_vertical_padding: ($field_min_height - ($field_line_height * 1rem)) / 2;
$field_horizontal_padding: .5rem;
$field_border_radius: .25rem;

// Dimensions
$width_faux_column: 1180px;
$max_width: 1366px;
$width_space_separator_small: 1.5rem;
$width_space_separator_large: 3rem;
$dropdown_arrow_width: .625rem;
$dropdown_arrow_height: .375rem;

// Spacing
$spacing: 1.5rem;
$spacing_xxxl: ($spacing + 2.5);

// Breakpoints
$bp_two_columns: 800px;
$bp_fit_column: $width_faux_column + 32px; // 1180 + 32 = 1212px
$bp_footer_inline: 820px;
$breakpoint_small: 767px;
$breakpoint_medium: $breakpoint_small + 1;
$breakpoint_large: 1000px;
$breakpoint_xlarge: 1199px;
$breakpoint_xxxlarge: 1600px;

// Font settings
$font_family: 'BoosterNextFY', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
$font_weight_light: 300;
$font_weight_normal: 400;
$font_weight_semibold: 500;
$font_weight_bold: 600;

// Buttons
$button_vertical_padding: .5rem;
$button_border_width: .0625rem;
$button_default_radius: $field_border_radius;
$button_inner_radius: $button_default_radius * 0.75;

// Basket button
$colour_button_basket_primary: #c8dbf1;
$colour_button_basket_secondary: $colour_secondary;
$colour_button_basket_notification: $colour_danger;
$button_basket_icon_width: 2.5rem;
$button_basket_horizontal_padding: 1rem;
$button_basket_badge_dimension: 1.25rem;

// Primary button
$colour_button_primary: $colour_primary;
$colour_button_primary_inverse: #fff; // font colour
$colour_button_primary_hover: mix($colour_primary, #000, 75%);
$colour_button_primary_active: #4bc4d6;
$colour_button_primary_click: $colour_button_primary_hover;
$colour_button_primary_click_shadow: #333;
$button_primary_shadow: inset 0 0 .5rem 0 rgba($colour_button_primary_click_shadow, .5);

// Secondary button
$colour_button_secondary: transparent;
$colour_button_secondary_inverse: $colour_primary; // font colour
$colour_button_secondary_border: $colour_button_secondary_inverse;
$colour_button_secondary_border_hover: $colour_secondary;
$colour_button_secondary_hover: $colour_secondary;
$button_secondary_shadow: inset 0 0 .5rem 0 rgba($colour_button_primary_click_shadow, .5);
// add arrow to block element
@mixin arrow($orientation: 'up', $color: #000, $size: 1em) {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    border-color: $color;

    width: 0;
    height: 0;
    z-index: 7;

    @if ($orientation == 'up') {
      border-top: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;

      border-bottom-width: $size;
      border-bottom-style: solid;
    } @else if ($orientation == 'down') {
      border-bottom: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;

      border-top-width: $size;
      border-top-style: solid;
    } @else if ($orientation == 'right') {
      left: 100%;
      top: 1.5 * $size;

      border-right: 0;
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;

      border-left-width: $size;
      border-left-style: solid;
    } @else if ($orientation == 'left') {
      right: 100%;
      top: 1.5 * $size;

      border-left: 0;
      border-top: $size solid transparent;
      border-bottom: $size solid transparent;

      border-right-width: $size;
      border-right-style: solid;
    }
  }
}
// enable/disable font smoothing
@mixin font-smoothing($toggle: true) {
  @if ($toggle == true) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin font-smoothing-off {
  @include font-smoothing(false);
}

// Theme
$color-dark-blue: #001e62; // Primary navigation, headings and body text, block colour and backdrops containing text.
$color-sky-blue: #009cde; // Block colour and graphics only. Not to be used for text or backdrops containing text.
$color-dark-sky: #007cbe; // Secondary navigation, textual links and backdrops containing text.
$color-light-sky: #cbdbef; // Minimal use. Borders and dividers.
$color-blue-white: #edf3fa; // Tertiary navigation menu backdrops for example, tab panes or universal nav. Large area backdrops.
$color-gray: #626262; // Minimal use. Muted text, date or time stamps, subtle icons and so on.
$color-gray-pale: #f2f2f2;
$color-malibu: #72cbff; // For links on dark blue, e.g. the site footer.
$color-white: #ffffff;
$color-danger: #cb4e0a;
$color-error: #cb0a0a;
$color-success: #29aa81;
$color-caution: #ebb140;
$color-info: $color-light-sky;

// Defaults
$color-background-page: $color-blue-white;
$color-background-block: $color-white;
$color-background-block-inverted: $color-dark-blue;
$color-font: $color-dark-blue;
$color-placeholder-font: $color-gray;
$color-font-inverted: $color-white;

// App bar
$color-appbar-background: $color-white;

// Banners
$color-banner-stripe: $color-sky-blue;
$color-banner-font: $color-white;

// Basket Dropdown
$color-basket-dropdown: #449bd8;
$color-basket-dropdown-badge: $color-danger;
$color-basket-dropdown-background: $color-white;

// Breadcrumbs
$color-breadcrumb-font: $color-white;

// Buttons
$color-button-active-focus: $color-malibu;
$color-button-primary: $color-dark-blue;
$color-button-primary-font: $color-white;
$color-button-primary-hover: $color-sky-blue;

$color-button-secondary: $color-dark-sky;
$color-button-secondary-font: $color-white;
$color-button-secondary-hover: darken($color-button-secondary, 5%);

$color-button-outline-background: transparent;
$color-button-outline-border: $color-dark-blue;
$color-button-outline-font: $color-dark-blue;
$color-button-outline-hover: $color-dark-blue;
$color-button-outline-font-hover: $color-white;

$color-button-outline-inverted-border: $color-white;
$color-button-outline-inverted-font: $color-white;
$color-button-outline-inverted-hover: $color-white;
$color-button-outline-inverted-font-hover: $color-dark-blue;

// Colour Picker
$color-label-border: $color-dark-blue;

// Date Picker
$color-date-picker-border: $color-dark-blue;
$color-date-picker-font: $color-dark-blue;
$color-selected-day-background: $color-dark-blue;
$color-selected-day-font: $color-white;
$color-hover-day-background: $color-light-sky;
$color-disabled-day-font: lighten($color-gray, 40%);

// Dropdowns
$color-dropdown-background: $color-white;
$color-dropdown-background-hover: $color-blue-white;
$color-dropdown-option-font: $color-dark-blue;
$color-dropdown-option-background-active: $color-white;
$color-dropdown-arrow: $color-dark-sky;

// Footer
$color-footer-background: $color-dark-blue;
$color-footer-border: lighten($color-dark-blue, 5%);
$color-footer-link: $color-malibu;
$color-footer-link-hover: $color-white;
$color-date-font: $color-white;

// Forms
$color-field-background: $color-blue-white;
$color-field-border: $color-light-sky;
$color-field-font: $color-dark-blue;
$color-field-disabled-background: $color-gray-pale;
$color-field-disabled-font: $color-gray;

// Headings
$color-section-heading: $color-dark-blue;
$color-page-heading-border: $color-sky-blue;

// Links
$color-link: $color-dark-sky;
$color-link-hover: darken($color-dark-sky, 10%);
$color-link-secondary: $color-malibu;
$color-link-secondary-hover: $color-sky-blue;

// Menu small
$color-menu-small-trigger-open: $color-sky-blue;
$color-menu-small-trigger-close: $color-white;
$color-menu-small-background: $color-dark-blue;
$color-menu-small-header-border: $color-dark-sky;
$color-menu-small-item-border: lighten($color-dark-blue, 5%);
$color-menu-small-font: $color-white;
$color-menu-small-link-hover: $color-sky-blue;

// Modals
$color-overlay-background: $color-dark-blue;
$color-modal-background: $color-white;
$color-modal-border: $color-light-sky;

// Navigation
$color-nav-link: $color-dark-blue;
$color-nav-link-hover: $color-dark-sky;
$color-nav-link-active: $color-dark-sky;
$color-app-nav-border: $color-light-sky;

// Notifications
$color-detail-block-background: $color-gray-pale;

// Order status timeline
$color-status-incomplete: $color-light-sky;
$color-status-complete: $color-dark-blue;
$color-status-complete-text: $color-dark-sky;
$color-status-step-background: $color-white;

// Pagination
$color-pagination-background: $color-white;
$color-pagination-link-hover: $color-dark-blue;
$color-pagination-link-current-background: $color-dark-blue;
$color-pagination-link-current-font: $color-white;

// Product Filters
$color-filters-background: $color-light-sky;

// Radio buttons
$color-radio-buttons-background: $color-white;
$color-radio-buttons-border: $color-light-sky;
$color-radio-buttons-border-selected: $color-dark-blue;
$color-radio-buttons-border-disabled: $color-field-disabled-font;
$color-radio-buttons-input-border: lighten($color-gray, 40%);
$color-radio-buttons-input-border-selected: #449bd8;

// Search bar
$color-search-link: $color-dark-blue;
$color-search-link-hover: $color-sky-blue;

// Tag configuration
$color-tag-config-background: $color-white;
$color-tag-config-border: $color-light-sky;
$color-tag-range-code-font: #d9d9d9;

// Tables
$color-table-border-primary: $color-dark-blue;
$color-table-border-secondary: $color-light-sky;

// Tiles
$color-tile-background: $color-white;
$color-tile-border: $color-light-sky;
$color-tile-font-primary: $color-dark-blue;
$color-tile-font-secondary: $color-dark-sky;

// Defaults
$max-width: 1366px;
$line-length: 640px;

// Default spacing
$spacing: 1.5rem; // 24px

$spacing-xxs: ($spacing - 1); // 8px
$spacing-xs: ($spacing * 0.5); // 12px
$spacing-s: ($spacing - 0.5); // 16px
$spacing-l: ($spacing + 0.5); // 32px
$spacing-xl: ($spacing + 1); // 40px
$spacing-xxl: ($spacing + 1.5); // 48px
$spacing-xxxl: ($spacing + 2.5); // 64px
$spacing-xxxxl: ($spacing + 3.5); // 80px

// Banners
$banner-stripe-s: 82px;
$banner-stripe-m: 98px;
$banner-stripe-l: 152px;
$banner-image-s: 200px;
$banner-image-l: 400px;
$banner-image-xl: 500px;

// Basket Dropdown
$button-basket-badge-dimension: 1.25rem;
$button-basket-badge-dimension-large: 1.75rem;

// Buttons
$button-group-margin: $spacing-xs;

// Dropdowns
$dropdown-link-padding: $spacing-xxs;
$dropdown-arrow-width: $spacing-xxs;
$dropdown-spacer-size: $spacing-xxs;

// Fonts
$font-family-default: 'BoosterNextFY', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;

$font-size-base: 16px;
$line-height-base: 1.5;
$line-height-headline: 1;
$line-height-subheadline: 1.25;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

// Forms
$label-font-size: 1.125rem; // 18px
$field-selection-border-radius: 0.375rem; // 6px
$field-line-height: 1.25;

// Radio buttons
$radio-button-input-size-small: 1rem; // 16px
$radio-button-input-size-large: 1.125rem; // 18px

// convert pixel to rem
@function rem($size) {
  $rem_size: strip-unit($size) / strip-unit($font_size_base);
  @return $rem_size * 1rem;
}

// Remove the unit
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// get ratio
@function ratio($width, $height) {
  @return ($height / $width) * 100%;
}

// get width
@function width($numerator, $denominator) {
  @return ($numerator / $denominator) * 100% !important;
}

.appear {
  opacity: 0;
  transform: translateX(30px);
}

.appearActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.12s ease-in-out, transform 0.12s ease-in-out;
}

.searchBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.searchItem {
  margin-left: $spacing-l;
  display: inline-block;

  &.input {
    margin-right: $spacing;
    width: 60%;

    @include xsmall-max {
      width: 100%;
      margin: 0 0 $spacing-xs;
    }
  }

  &.input::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
}

.searchInput {
  position: relative;

  .input:not(:valid) ~ .resetIcon {
    display: none;
  }

  .resetIcon {
    position: absolute;
    top: 50%;
    right: $spacing-xl;
    transform: translateY(-50%);
    width: 0.875rem;
    height: 0.875rem;
    padding: 0;
    background-color: #c8c8c8;
    background-image: url('/images/icons/clear-search.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 45%;
    border: none;
    border-radius: 50%;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.menuSearch {
  @include inline-block(middle);
  float: right;
}

.closeLink {
  @include inline-block;
  font-weight: $font-weight-medium;
  padding: $spacing-xxs $spacing-xs;
  color: $color-search-link;

  &:hover {
    text-decoration: none;
    color: $color-search-link-hover;
  }

  @include xlarge {
    padding: $spacing-xxs rem(23);
  }

  .closeIcon {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-top: -0.25em;
    margin-right: 0.5em;

    use {
      fill: currentColor;
    }
  }
}
