// ============================================================================
// Links
// ============================================================================

a {
  color: $color-link;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
  }
}
