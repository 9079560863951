// ============================================================================
// Shared
// ============================================================================

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
.block-item,
hr,
table,
fieldset,
figure,
pre {
  margin-bottom: $spacing-s;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol,
dd {
  margin-left: $spacing;
}
