$font_weighting_values: (300, 400, 500, 600);
$font_weighting_names: (
  'BoosterNextFY-Thin',
  'BoosterNextFY-Regular',
  'BoosterNextFY-Medium',
  'BoosterNextFY-Bold'
);

@each $weight, $name in zip($font_weighting_values, $font_weighting_names) {
  @font-face {
    font-family: 'BoosterNextFY';
    src: url('/fonts/booster-next-fy-#{$weight}.eot');
    src: url('/fonts/booster-next-fy-#{$weight}.eot?#iefix') format('embedded-opentype'),
      url('/fonts/booster-next-fy-#{$weight}.woff2') format('woff2'),
      url('/fonts/booster-next-fy-#{$weight}.woff') format('woff'),
      url('/fonts/booster-next-fy-#{$weight}.ttf') format('truetype');
    font-style: normal;
    font-weight: $weight;
  }
}
