// ============================================================================
// Buttons & button-like elements
// ============================================================================

button,
a[role='button'] {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  background-color: $color-button-primary;
  color: $color-button-primary-font;
  font: inherit;
  text-align: center;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  line-height: 1;
  cursor: pointer;

  padding: 0.75rem $spacing-l 0.625rem;

  border-color: $color-button-primary;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  transition: color 0.1s, background-color 0.1s, border-color 0.1s;

  // Ensures that buttons with too much text don't overflow
  // past page width on smaller screens. This ensures text
  // is truncated with no word wrap and text overflow ellipsis
  @include xsmall-max {
    width: 100%;
    max-width: 100%;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 3px $color-button-active-focus;
  }

  &,
  &:hover {
    text-decoration: none;
  }

  &:hover {
    background-color: $color-button-primary-hover;
    border-color: $color-button-primary-hover;
    color: $color-button-primary-font;
    text-decoration: none;
    outline: none;
  }

  &[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5 !important;
  }

  > div {
    margin: 0.5625rem auto !important;
  }
}
